.afterpay_pbi-image.payment-image {
  margin-top: -3px;
  position: relative; }

.afterpay-widget-message {
  font-size: 12px;
  color: #707070; }
  .afterpay-widget-message .afterpay-link .icon {
    display: inline-block;
    width: 17px;
    height: auto; }
  .afterpay-widget-message .afterpay-image {
    vertical-align: middle;
    width: 6em;
    margin: 5px 0; }

.modal.show {
  display: block; }

.afterpayModal {
  background: rgba(0, 0, 0, 0.5); }
  .afterpayModal .quick-view-dialog {
    max-width: 48em; }
  .afterpayModal .modal-body {
    padding: 0;
    max-height: initial; }
  .afterpayModal .modal-content {
    border-radius: 0.8125em;
    overflow: hidden;
    position: absolute; }
    .afterpayModal .modal-content .modal-header {
      border-bottom: none;
      height: auto;
      padding: 1rem; }
      .afterpayModal .modal-content .modal-header button {
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        right: 1.125em;
        top: 1.125em;
        padding: 0;
        border: none; }
        .afterpayModal .modal-content .modal-header button .close-button {
          position: relative;
          top: -0.35em;
          font-size: 2.5em;
          color: var(--skin-primary-color); }
        .afterpayModal .modal-content .modal-header button:hover .close-button {
          color: var(--skin-primary-color);
          opacity: 0.5; }
    .afterpayModal .modal-content .modal-body {
      padding: 0;
      max-height: initial; }
  .afterpayModal.modal.show .modal-dialog {
    transition: transform 0.3s ease-out, top 0.1s ease-out; }

.cancel-symbol {
  position: relative;
  bottom: 0.4375em;
  right: 0.3125em;
  background-color: transparent; }

.terms-content {
  text-align: center;
  color: #878787;
  font-size: 0.9375em;
  margin: 0.75em 0; }

.copy-rights {
  text-align: center; }

.redirect-text {
  text-align: center;
  margin-top: 2.1875em;
  font-size: 1.375em; }

.checkout-afterpay-message {
  text-align: center; }

.afterpay-widget-message-text {
  color: #9bcee8; }

.afterpay-widget-message-text strong {
  color: #333; }

.afterpay-widget-redirect-label {
  margin: 20px 0; }

.checkout-afterpay-message {
  background: #f9f9f9;
  padding: 25px; }

#afterpaInstallmentChart {
  margin: 20px 0; }

/* Afterpay checkout widget */
.checkout-afterpay-message {
  background: #f9f9f9;
  padding: 25px; }
  @media screen and (max-width: 767px) {
    .checkout-afterpay-message {
      padding: 25px 10px; } }

#afterpaInstallmentChart th {
  text-align: center;
  color: #9bcee8;
  font-size: 1em;
  width: 25%; }
  @media screen and (max-width: 767px) {
    #afterpaInstallmentChart th {
      font-size: .85em; } }

#afterpaInstallmentChart td {
  text-align: center;
  padding-top: 0.9375em;
  padding-bottom: 0;
  color: #999;
  font-size: .85em; }
  @media screen and (max-width: 767px) {
    #afterpaInstallmentChart td {
      font-size: .75em; } }

.afterpay-circle {
  min-width: 24px;
  height: auto; }

.afterpay-checkout-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/checkout-with-afterpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center; }

.clearpay-checkout-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/checkout-with-clearpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center; }

.afterpay-buynow-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/buy-now-with-afterpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-width: 250px; }

.clearpay-buynow-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/buy-now-with-clearpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-width: 250px; }

.afterpay-placeorder-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/place-order-with-afterpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-width: 250px; }

.clearpay-placeorder-button {
  background-color: #000;
  color: #fff !important;
  background-image: url("https://static.afterpay.com/button/place-order-with-clearpay/white-on-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-width: 250px; }

.afterpay-hide {
  display: none !important; }

.afterpay-widget-hideuntilready {
  visibility: hidden; }

.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

/**** Afterpay Modal - SG ****/
.afterpayModal {
  text-align: center;
  width: 100%; }

.afterpay-logoContainer {
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 767px) {
    .afterpay-logoContainer {
      display: block; } }
  .afterpay-logoContainer .afterpay-logo {
    text-align: center; }

.afterpay-logo {
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .afterpay-logo {
      width: 200px;
      height: auto; } }

.afterpay-iconContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .afterpay-iconContainer {
      flex-wrap: wrap; } }
  .afterpay-iconContainer .afterpay-iconContainerSection {
    width: 23%; }
    .afterpay-iconContainer .afterpay-iconContainerSection .desktop-only {
      display: block; }
    .afterpay-iconContainer .afterpay-iconContainerSection .mobile-only {
      display: none; }
    @media only screen and (max-width: 767px) {
      .afterpay-iconContainer .afterpay-iconContainerSection {
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: 20px; }
        .afterpay-iconContainer .afterpay-iconContainerSection img {
          height: auto !important;
          width: 30% !important;
          margin-right: 10%; }
        .afterpay-iconContainer .afterpay-iconContainerSection .desktop-only {
          display: none; }
        .afterpay-iconContainer .afterpay-iconContainerSection .mobile-only {
          display: block; } }
    .afterpay-iconContainer .afterpay-iconContainerSection img {
      width: 100%; }
  .afterpay-iconContainer p {
    text-align: center; }

.terms-content-us,
.terms-content-uk {
  padding-top: 0.625rem;
  width: 80%;
  margin: auto;
  margin-bottom: 2.5rem;
  text-align: center; }
  .terms-content-us,
  .terms-content-us a,
  .terms-content-uk,
  .terms-content-uk a {
    font-size: 0.625rem; }
  @media only screen and (max-width: 767px) {
    .terms-content-us,
    .terms-content-uk {
      text-align: left; }
      .terms-content-us,
      .terms-content-us a,
      .terms-content-uk,
      .terms-content-uk a {
        font-size: 0.625rem;
        color: var(--color-grey-aa); } }
  .terms-content-us__block-more,
  .terms-content-uk__block-more {
    line-height: normal; }
